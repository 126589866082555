<template>
  <div>
    <h1 class="text-h4">Управление продажами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-card-title>Добавить новый товар в заказ {{ order.code }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
          <v-textarea
            v-model="form.data.content"
            label="Состав"
            counter="1024"
            rows="1"
            auto-grow
            :rules="form.rule.content"
          />
          <v-text-field
            v-model="form.data.price"
            label="Цена"
            counter="12"
            :rules="form.rule.price"
          />
          <v-text-field
            v-model="form.data.quantity"
            label="Количество"
            counter="12"
            :rules="form.rule.quantity"
          />
          <div class="d-flex justify-start">
            <div class="align-self-center subtitle-1">ИТОГО:</div>
            <div class="align-self-center subtitle-1 ml-2">{{ lineAmount | money }}</div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :to="{name: 'order.view', params: { id: orderId }}">Отмена</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import ToastService from '@/service/ToastService'
import { mapActions, mapState } from 'vuex'
import { ruleNumeric, ruleRequired } from '@/utils/FormRules'
import OrderLineCreateItemForm from '@/model/order/OrderLineCreateItemForm'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderLineCreateWithNewItemPage',
  props: {
    orderId: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      form: {
        valid: true,
        data: {
          name: null,
          content: null,
          quantity: 1,
          price: null
        },
        rule: {
          name: [ruleRequired()],
          content: [],
          quantity: [ruleRequired(), ruleNumeric()],
          price: [ruleRequired(), ruleNumeric()]
        }
      }
    }
  },
  computed: {
    ...mapState('order_line_create', ['order', 'busy']),
    lineAmount() {
      return this.form.data.price * this.form.data.quantity
    }
  },
  created() {
    this.fetchData({ orderId: this.orderId })
      .then()
  },
  methods: {
    ...mapActions('order_line_create', ['fetchData','createOrderLineWithNewItem']),
    submitForm() {
      const form = OrderLineCreateItemForm.create(this.form.data)

      this.createOrderLineWithNewItem({ form })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.$router.push({ name: 'order.view', params: { id: this.orderId } })
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Добавить в строку'
      }
    }
  }
}
</script>
