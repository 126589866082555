export default class OrderLineCreateItemForm {
  constructor(data) {
    this.name = data?.name
    this.content = data?.content
    this.price = data?.price ?? 0
    this.quantity = data?.quantity ?? 0
  }

  static create(data) {
    return new OrderLineCreateItemForm(data)
  }

}
